<template lang="pug">
form.tile-filters(v-if="filters && filters.length")
  .tile-filters__layer(@click="closeFilters")
  .tile-filters__btn-close(@click="closeFilters")
  .tile-filters__cont
    .tile-filters__inner
      template(v-for="(filter, i) in filters", :key="i")
        template(v-if="filter.type === 'range'")
          h4.tile-filters__name(
            :class="{'tile-filters__name_active': openFilters.includes('all') || openFilters.includes(filter.slug)}"
            @click="toggleFilters"
          ) {{filter.name}}
          .tile-filters__input-area
            label.tile-filters__input-box
              .tile-filters__input-note.text-tiny От
              input.tile-filters__input.input(
                  type="number",
                  v-model.number= "filter.min_current",
                  :placeholder="filter.min",
                  :min="filter.min_without_filters",
                  :max="filter.max_without_filters"
                )
            label.tile-filters__input-box
              .tile-filters__input-note.text-tiny До
              input.tile-filters__input.input(
                  type="number",
                  v-model.number = "filter.max_current",
                  :placeholder="filter.max",
                  :min="filter.min_without_filters",
                  :max="filter.max_without_filters",
                )
        template(v-else-if="filter.type === 'colorlist' || filter.type === 'checklist'")
          h4.tile-filters__name(
            :class="{'tile-filters__name_active': openFilters.includes('all') || openFilters.includes(filter.slug)}"
            @click="toggleFilters"
          ) {{filter.name}}
          .tile-filters__list
            label.tile-filters__label(v-for="(item, i) in filter.list", :key="i")
              input.tile-filters__input-checkbox(
                  type="checkbox",
                  :value="item.value",
                  v-model="item.is_current",
                )
              .tile-filters__input-checkbox-my
              span.tile-filters__label-name.tile-filters__label-name_color.text {{item.label}}

        template(v-else-if="filter.type === 'radiolist'")
          h4.tile-filters__name(
            :class="{'tile-filters__name_active': openFilters.includes('all') || openFilters.includes(filter.slug)}"
            @click="toggleFilters"
          ) {{filter.name}}
          .tile-filters__list
            label.tile-filters__label
              input.tile-filters__input-radio(v-model="filter.current_value", type="radio", :value="null")
              .tile-filters__input-radio-my
              span.tile-filters__label-name.text Все
            label.tile-filters__label(v-for="(item, i) in filter.list", :key="i")
             input.tile-filters__input-radio(v-model="filter.current_value", type="radio", :value="item.value")
             .tile-filters__input-radio-my
             span.tile-filters__label-name.text {{item.label}}
    .tile-filters__btn-box
      button.tile-filters__submit.text.button-orange(@click="applyFilters") Применить
      button.tile-filters__reset.text(type="button", @click="resetFilters") Сбросить
</template>

<script>

import '@/assets/styles/components/tile-filters.sass'

export default {
  name: 'TileFilters',
  props: {
    filtersStart: Array,
    openFilters: Array
  },
  data () {
    return {
      filters: []
    }
  },
  emits: ['close-filters', 'apply-filters', 'reset-filters'],
  methods: {
    closeFilters () {
      this.$emit('close-filters')
    },
    applyFilters () {
      this.$emit('apply-filters', this.filters)
    },
    resetFilters () {
      this.$emit('reset-filters')
    },
    toggleFilters ({ currentTarget }) {
      currentTarget.classList.toggle('tile-filters__name_active')
    }
  },
  mounted () {
    this.filters = JSON.parse(JSON.stringify(this.filtersStart))
    setTimeout(() => {
      const activeItem = document.querySelector('.tile-filters__name_active')
      if (activeItem) {
        const container = document.querySelector('.tile-filters__cont')
        const position = activeItem.offsetTop
        if (container) {
          container.scrollTo({
            top: position - 30,
            behavior: 'smooth'
          })
        }
      }
    }, 0)
  }
}
</script>
