<template lang='pug'>
ul.active-filters(v-if="isShowFilters")
  template(v-for="(filter, i) in filters", :key="i" )
    template(v-if="filter.type === 'range'")
      li.active-filters__item(v-if="filter.min_current", @click="removeFromFilters(filter.slug, filter.min_current, filter.type, 'min')")
        span.active-filters__name.text-tiny {{filter.name}}: от {{filter.min_current}}
        span.active-filters__btn
      li.active-filters__item(v-if="filter.max_current", @click="removeFromFilters(filter.slug, filter.max_current, filter.type, 'max')")
        span.active-filters__name.text-tiny {{filter.name}}: до {{filter.max_current}}
        .active-filters__btn
    template(v-else-if="filter.type === 'colorlist' || filter.type === 'checklist'" )
      template(v-for="item in filter.list")
        li.active-filters__item(v-if="item.is_current", @click="removeFromFilters(filter.slug, item.value, filter.type)")
          span.active-filters__name.text-tiny {{filter.name}}: {{item.label}}
          span.active-filters__btn
    template(v-else-if="filter.type === 'radiolist'")
      li.active-filters__item(v-if="filter.current_value", @click="removeFromFilters(filter.slug, filter.current_value, filter.type)")
        span.active-filters__name.text-tiny {{filter.current_value}}
        span.active-filters__btn
  button.active-filters__reset.text-tiny(type="button", @click="resetFilters") Сбросить фильтры
</template>

<script>
import '@/assets/styles/components/active-filters.sass'

export default {
  name: 'ActiveFilters',
  props: {
    filters: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    isShowFilters () {
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i]
        if (filter.min_current || filter.max_current) return true
        if (filter.current_value) return true
        if (filter.list) {
          for (let j = 0; j < filter.list.length; j++) {
            if (filter.list[j].is_current) return true
          }
        }
      }
      return false
    }
  },
  emits: ['remove-from-filters', 'reset-filters'],
  methods: {
    removeFromFilters (slug, value, type, field) {
      this.$emit('remove-from-filters', slug, value, type, field)
    },
    resetFilters () {
      this.$emit('reset-filters')
    }
  }
}
</script>
