<template lang='pug'>
.filters-line
  .filters-line__layer(
      :class="{'filters-line__layer_active' : isOpenSort}",
      @click="closeSort"
    )
  .filters-line__cont.cont
    .filters-line__top
      .filters-line__buttons
        button.filters-line__all.text(
            type="button"
            v-if="filters.length && products?.length",
            @click="openFilters"
          ) Все фильтры
        button.filters-line__btn.text(
          v-if="filters.length && filters.find(item => item.slug === 'price') && products?.length"
          type="button",
          @click="openPriceFilter"
        ) Цена
        button.filters-line__btn.text(
          v-if="filters.length && filters.find(item => item.slug === 'dlina' || item.slug === 'shirina' || item.slug === 'vysota') && products?.length"
          type="button",
          @click="openSizeFilter"
        ) Размеры
        button.filters-line__btn.text(
          v-if="filters.length && filters.find(item => item.slug === 'tsvet') && products?.length"
          type="button",
          @click="openColorFilter"
        ) Цвет
          span(v-if="colorCount" ).filters-line__btn-count {{colorCount}}
        span.filters-line__result.text(v-if="productCount" ) Найдено {{productCount}}
      .filters-line__box
        .filters-line__groups(v-if="groups.length", ref="filtersLineGroups" )
          button.filters-line__groups-button.text(
              :class="{'filters-line__groups-button_active': isOpenGroups }"
              type="button",
              @click="toggleGroups"
            ) По группам
          .filters-line__groups-box(v-if="isOpenGroups")
            .filters-line__groups-cont
              .filters-line__groups-title-box
                span.filters-line__groups-title По группам
                button.filters-line__groups-close(type="button", @click="closeGroups")
              .filters-line__groups-list(:class="{'filters-line__groups-list_two-column' : groups.length >= 3 && groups.length < 5, 'filters-line__groups-list_one-column' : groups.length <= 2 }")
                template(v-for="(item, i) in groups", :key="i")
                  .filters-line__groups-item
                    span.filters-line__groups-headline {{item.headline}}
                    .filters-line__groups-links
                      router-link.filters-line__groups-link.link_black-orange(
                          v-for="link in item.list",
                          :key="link.slug",
                          :to="{ name: 'Catalog', params: { slug: link.slug} }"
                      ) {{link.name}}
        .filters-line__sort(ref="filtersLineSort" )
          button.filters-line__sort-button.text(
            :class="{'filters-line__sort-button_active': isOpenSort }"
            type="button",
            @click="toggleSort"
          ) {{activeSort.name}}
          .filters-line__sort-box(v-if="isOpenSort")
            .filters-line__sort-cont
              .filters-line__sort-title-box
                span.filters-line__sort-title Выводить сначала
                button.filters-line__sort-close(type="button", @click="closeSort")
              .filters-line__sort-item.link_black-orange(
                v-for="item in sortList",
                :key="item.sort",
                :class="{'filters-line__sort-item_active': item.sort === activeSort.sort}",
                @click="sortProducts(item)",
              ) {{item.name}}
    active-filters(:filters="filters", @removeFromFilters="removeFromFilters", @reset-filters="resetFilters")
  tile-filters(v-if="filters.length && isOpenFilters",
    :filters-start="filters",
    :open-filters="openFilterSlugs",
    @close-filters="closeFilters",
    @apply-filters="applyFilters",
    @reset-filters="resetFilters"
  )
</template>

<script>
import '@/assets/styles/components/filters-line.sass'
import ActiveFilters from './ActiveFilters'
import TileFilters from './TileFilters'
export default {
  name: 'FiltersLine',
  components: { TileFilters, ActiveFilters },
  props: {
    filtersStart: Array,
    sort: String,
    products: Array,
    productCount: Number,
    groups: {
      type: Array,
      default () {
        return []
      }
    },
    colorCount: Number
  },
  emits: ['apply-filters', 'sort-products'],
  data () {
    return {
      activeSort: { name: 'Популярные', sort: 'popular' },
      sortList: [
        { name: 'Сначала дешевые', sort: 'low-high' },
        { name: 'Сначала дорогие', sort: 'high-low' },
        { name: 'Популярные', sort: 'popular' },
        { name: 'Новинки', sort: 'new' },
        { name: 'Скидка в ₽', sort: 'discount-rub' },
        { name: 'Скидка в %', sort: 'discount-percent' }
      ],
      isShowMobileFilters: false,
      isOpenFilters: false,
      isOpenGroups: false,
      isOpenSort: false,
      filters: [],
      openFilterSlugs: ['all']
    }
  },
  computed: {
    selectedFilters () {
      const filters = {}
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i]
        if (filter.type === 'range') {
          filters[filter.slug] = { min: filter.min_current, max: filter.max_current }
        } else if ((filter.type === 'colorlist' || filter.type === 'checklist') && filter.list) {
          const activeValues = []
          filter.list.forEach(item => {
            if (item.is_current) {
              activeValues.push(item.value)
            }
          })
          filters[filter.slug] = activeValues
        } else if (filter.type === 'radiolist') {
          filters[filter.slug] = filter.current_value
        }
      }
      return filters
    }
  },
  methods: {
    sortProducts (item) {
      this.isShowMobileFilters = false
      if (window.innerWidth <= 1240) {
        const prevActiveSort = this.activeSort
        this.sortList = this.sortList.filter(sortItem => sortItem.sort !== item.sort)
        this.sortList.push(prevActiveSort)
      }
      this.activeSort = item
      this.$emit('sort-products', item.sort)
    },
    showMobileFilters () {
      this.isShowMobileFilters = true
    },
    openFilters () {
      this.openFilterSlugs = ['all']
      this.isOpenFilters = true
    },
    openPriceFilter () {
      this.openFilterSlugs = ['price']
      this.isOpenFilters = true
    },
    openColorFilter () {
      this.openFilterSlugs = ['tsvet']
      this.isOpenFilters = true
    },
    openSizeFilter () {
      this.openFilterSlugs = ['dlina', 'shirina', 'vysota']
      this.isOpenFilters = true
    },
    closeFilters () {
      this.isOpenFilters = false
    },
    applyFilters (filters) {
      this.filters = filters
      this.$emit('apply-filters', this.selectedFilters)
    },
    resetFilters () {
      for (let i = 0; i < this.filters.length; i++) {
        const filter = this.filters[i]
        if (filter.type === 'range') {
          filter.min_current = null
          filter.max_current = null
        } else if (filter.type === 'radiolist') {
          filter.current_value = null
        } else if (filter.type === 'colorlist' || filter.type === 'checklist') {
          for (let j = 0; j < filter.list.length; j++) {
            filter.list[j].is_current = false
          }
        }
      }
      this.$emit('apply-filters', this.selectedFilters)
    },
    removeFromFilters (slug, value, type, field) {
      const removedItem = this.filters.find(item => item.slug === slug)
      if (removedItem.type === 'radiolist') {
        removedItem.current_value = null
      } else if (removedItem.type === 'range') {
        field === 'min' ? removedItem.min_current = null : removedItem.max_current = null
      } else if (removedItem.type === 'colorlist' || removedItem.type === 'checklist') {
        removedItem.list.find(element => element.value === value).is_current = false
      }
      this.$emit('apply-filters', this.selectedFilters)
    },
    toggleGroups () {
      this.isOpenGroups = !this.isOpenGroups
    },
    toggleSort () {
      this.isOpenSort = !this.isOpenSort
    },
    closeGroups () {
      this.isOpenGroups = false
    },
    closeSort () {
      this.isOpenSort = false
    },
    eventClickDocument ({ target }) {
      if (this.$refs.filtersLineGroup && !this.$refs.filtersLineGroups.contains(target)) this.closeGroups()
      if (this.$refs.filtersLineSort && !this.$refs.filtersLineSort.contains(target)) this.closeSort()
    }
  },
  mounted () {
    this.filters = this.filtersStart

    for (let i = 0; i < this.sortList.length; i++) {
      if (this.sortList[i].sort === this.sort) {
        this.activeSort = this.sortList[i]
      }
    }
    window.addEventListener('click', this.eventClickDocument)
  },
  unmounted () {
    window.removeEventListener('click', this.eventClickDocument)
  },
  beforeRouteUpdate (to, from, next) {
    this.resetFilters()
    next()
  }
}
</script>
